var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.reviewTransactionModal,"fullscreen":"","persistent":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Controleer transactie")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_c('strong',[_vm._v("Rekeninghouder:")]),_vm._v(" "+_vm._s(_vm.transaction.debtorName)+" ")]),_c('p',[_c('strong',[_vm._v("Ontvangen:")]),_vm._v(" "+_vm._s(_vm.toCurrency(_vm.transaction.amount))+" ")]),_c('p',[_c('strong',[_vm._v("Beschrijving:")]),_vm._v(" "+_vm._s(_vm.transaction.description)+" ")])]),_c('v-col',[_c('p',[_c('strong',[_vm._v("Klant:")]),_vm._v(" "+_vm._s(_vm.client.name))]),_c('p',[_c('strong',[_vm._v("Stad:")]),_vm._v(" "+_vm._s(_vm.client.city))]),_c('p',[_c('strong',[_vm._v("Klantnr:")]),_vm._v(" "+_vm._s(_vm.client.clientNumber))])]),_c('v-col',[_c('search-client',{attrs:{"searchString":_vm.searchString},on:{"setSearchString":_vm.setSearchString,"get-results":_vm.setClient,"clear-input":_vm.clearClient}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.invoiceHeaders,"items":_vm.outstandingInvoices,"item-key":"_id","options":_vm.options,"show-select":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.invoiceStatuses[item.status].color}},[_vm._v(_vm._s(_vm.invoiceStatuses[item.status].text))])]}}],null,true),model:{value:(_vm.selectedInvoices),callback:function ($$v) {_vm.selectedInvoices=$$v},expression:"selectedInvoices"}}),_c('fieldset',[_c('legend',[_c('h2',[_vm._v("Geselecteerde facturen")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selectedInvoicesHeaders,"items":_vm.selectedInvoices,"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.invoiceStatuses[item.status].color}},[_vm._v(_vm._s(_vm.invoiceStatuses[item.status].text))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.date)))]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.dueDate)))]}},{key:"item.grossTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.grossTotal)))]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.debit)))]}},{key:"item.transactionAmount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.transactionAmount),callback:function ($$v) {_vm.$set(item, "transactionAmount", _vm._n($$v))},expression:"item.transactionAmount"}}),_c('v-checkbox',{attrs:{"label":"Volledig"},on:{"click":function($event){return _vm.setRemainderAmount(item)}},model:{value:(item.remainderAmount),callback:function ($$v) {_vm.$set(item, "remainderAmount", $$v)},expression:"item.remainderAmount"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-footer',{staticClass:"d-flex justify-space-between",attrs:{"fixed":""}},[_c('div',{staticClass:"footer-div"}),_c('v-btn-toggle',{staticClass:"d-flex justify-center footer-div"},[_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.closeReviewTransactionModal}},[_vm._v("Afsluiten")]),_c('v-btn',{attrs:{"disabled":_vm.remainder !== 0,"color":"green"},on:{"click":_vm.confirmTransactionUnderReview}},[_vm._v("Bevestigen")])],1),_c('div',{staticClass:"text-right footer-div"},[_c('h1',[_vm._v("Resterend: "+_vm._s(_vm.toCurrency(_vm.remainder)))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }