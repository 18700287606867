<template>
  <v-dialog :value="reviewTransactionModal" fullscreen persistent>
    <v-card>
      <v-card-title>Controleer transactie</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            ><p>
              <strong>Rekeninghouder:</strong> {{ transaction.debtorName }}
            </p>
            <p>
              <strong>Ontvangen:</strong> {{ toCurrency(transaction.amount) }}
            </p>
            <p>
              <strong>Beschrijving:</strong> {{ transaction.description }}
            </p></v-col
          >
          <v-col
            ><p><strong>Klant:</strong> {{ client.name }}</p>
            <p><strong>Stad:</strong> {{ client.city }}</p>
            <p><strong>Klantnr:</strong> {{ client.clientNumber }}</p></v-col
          >
          <v-col>
            <search-client
              :searchString="searchString"
              @setSearchString="setSearchString"
              @get-results="setClient"
              @clear-input="clearClient"
            ></search-client>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedInvoices"
          :headers="invoiceHeaders"
          :items="outstandingInvoices"
          item-key="_id"
          :options="options"
          show-select
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip label :color="invoiceStatuses[item.status].color">{{
              invoiceStatuses[item.status].text
            }}</v-chip>
          </template>
        </v-data-table>
        <fieldset>
          <legend><h2>Geselecteerde facturen</h2></legend>
          <v-data-table
            :headers="selectedInvoicesHeaders"
            :items="selectedInvoices"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip label :color="invoiceStatuses[item.status].color">{{
                invoiceStatuses[item.status].text
              }}</v-chip></template
            >
            <template v-slot:[`item.date`]="{ item }">
              {{ toDateString(item.date) }}</template
            >
            <template v-slot:[`item.dueDate`]="{ item }">
              {{ toDateString(item.dueDate) }}</template
            >
            <template v-slot:[`item.grossTotal`]="{ item }">
              {{ toCurrency(item.grossTotal) }}</template
            >
            <template v-slot:[`item.debit`]="{ item }">
              {{ toCurrency(item.debit) }}</template
            >
            <template v-slot:[`item.transactionAmount`]="{ item }">
              <v-text-field
                type="number"
                v-model.number="item.transactionAmount"
              ></v-text-field
              ><v-checkbox
                @click="setRemainderAmount(item)"
                v-model="item.remainderAmount"
                label="Volledig"
              ></v-checkbox
            ></template>
          </v-data-table>
        </fieldset>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed class="d-flex justify-space-between">
          <div class="footer-div"></div>
          <v-btn-toggle class="d-flex justify-center footer-div">
            <v-btn color="red" @click="closeReviewTransactionModal"
              >Afsluiten</v-btn
            >
            <v-btn
              :disabled="remainder !== 0"
              color="green"
              @click="confirmTransactionUnderReview"
              >Bevestigen</v-btn
            >
          </v-btn-toggle>
          <div class="text-right footer-div">
            <h1>Resterend: {{ toCurrency(remainder) }}</h1>
          </div>
        </v-footer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { fetchGET, toCurrency, toDateString } from "../js/functions"
import searchClient from "@/components/searchClient.vue"
export default {
  name: "reviewTransactionModal",
  props: {
    reviewTransactionModal: Boolean,
    transactionUnderReview: Object,
    transactionUnderReviewIndex: Number
  },
  data() {
    return {
      transaction: this.transactionUnderReview,
      client: {},
      selectedInvoices: [...this.transactionUnderReview.invoices],
      invoiceHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Openstaand", value: "debit" }
      ],
      selectedInvoicesHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Openstaand", value: "debit" },
        { text: "Betaling", value: "transactionAmount" }
      ],
      outstandingInvoices: [],
      options: {
        sortBy: ["date"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 10
      },
      searchString: ""
    }
  },
  methods: {
    toCurrency(n) {
      return toCurrency(n)
    },
    toDateString(d) {
      return toDateString(d)
    },
    setClient(client) {
      this.client = client
      this.fetchOutstandingInvoices(this.client.clientNumber)
    },
    setSearchString(val) {
      this.searchString = val
    },
    clearClient() {
      this.searchString = ""
      this.client = {}
      this.outstandingInvoices = []
    },
    async fetchOutstandingInvoices(clientNumber) {
      this.outstandingInvoices = await fetchGET(
        "transactionOutstandingInvoices",
        clientNumber
      )
    },
    setRemainderAmount(invoice) {
      if(invoice.remainderAmount){
if(this.transaction.amount - invoice.debit >= 0) {
        invoice.transactionAmount = invoice.debit
      } else {
        invoice.transactionAmount = this.remainder
      }
      }
      else{
        invoice.transactionAmount = 0
      }
    },
    confirmTransactionUnderReview() {
      this.transaction.invoices = this.selectedInvoices
      this.$emit(
        "confirm-transaction-under-review",
        this.transaction,
        this.transactionUnderReviewIndex
      )
    },
    closeReviewTransactionModal() {
      this.$emit("close-review-transaction-modal")
    }
  },
  computed: {
    remainder() {
      return (
        this.transaction.amount -
        this.selectedInvoices.reduce(
          (acc, invoice) => acc + invoice.transactionAmount,
          0
        )
      )
    },
    invoiceStatuses() {
      return this.$store.state.settings.invoiceStatuses
    }
  },
  async mounted() {
    if (
      this.transaction.invoices.length &&
      this.transaction.invoices[0].clientNumber
    ) {
      this.client = this.transaction.invoices[0].client
      await this.fetchOutstandingInvoices(this.client.clientNumber)
    }
  },
  components: {
    searchClient
  }
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #ddd;
}
td {
  height: 50px;
  border: 1px solid lightgrey;
}
.hasError {
  background: red;
  color: white;
}
.noError {
  background: green;
  color: white;
}
.v-application .red,
.v-application .orange,
.v-application .light-green,
.v-application .black {
  color: white;
  border-color: #013fba !important;
}
.footer-div {
  width: 33%;
}
</style>