<template>
  <div>
    <p>Selecteer een CAMT.053 bestand:</p>
    <input @change="readFile" id="camt053" type="file" />
    <v-btn @click="reset">Reset</v-btn>
    <v-card>
      <table>
        <tr>
          <th v-for="header of transactionHeaders" :key="header">
            {{ header }}
          </th>
        </tr>
        <tr v-for="(transaction, index) of transactions" :key="index">
          <td>
            <v-chip
              label
              :color="transactionStatuses[transaction.status].color"
              >{{ transactionStatuses[transaction.status].text }}</v-chip
            >
          </td>
          <td>{{ transaction.bookingDate.toLocaleDateString("nl-NL") }}</td>
          <td>{{ transaction.debtorName }}</td>
          <td>{{ transaction.description }}</td>
          <td>{{ toCurrency(transaction.amount) }}</td>
          <td v-if="transaction.status == 1">Bevestigd</td>
          <td v-else-if="transaction.invoices.length && !transaction.success">
            <v-btn
              color="orange"
              @click="openReviewTransactionModal(transaction, index)"
              >Controleren</v-btn
            >
          </td>
          <td
            v-else-if="
              (transaction.invoices.length == 1 &&
                transaction.invoices[0].success) ||
                transaction.success
            "
          >
            <v-btn color="green" @click="confirmTransaction(transaction, index)"
              >Bevestigen</v-btn
            >
          </td>
          <td v-else>
            <v-btn
              color="primary"
              @click="openReviewTransactionModal(transaction, index)"
              >Zoeken</v-btn
            >
          </td>
        </tr>
      </table>
    </v-card>
    <review-transaction-modal
      v-if="reviewTransactionModal"
      :transactionUnderReview="transactionUnderReview"
      :reviewTransactionModal="reviewTransactionModal"
      :transactionUnderReviewIndex="transactionUnderReviewIndex"
      @confirm-transaction-under-review="confirmTransactionUnderReview"
      @close-review-transaction-modal="closeReviewTransactionModal"
    ></review-transaction-modal>
  </div>
</template>
<script>
import reviewTransactionModal from "@/components/reviewTransactionModal.vue"
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  successHandler,
  toCurrency,
  toDateString
} from "../js/functions"
export default {
  name: "camt053Parser",
  data() {
    return {
      invoices: [],
      transactions: [],
      transactionUnderReview: {},
      reviewTransactionModal: false,
      transactionHeaders: [
        "Status",
        "Datum",
        "Begunstigde",
        "Omschrijving",
        "Ontvangen",
        "Bevestigen"
      ],
      transactionStatuses: [
        {
          status: 0,
          color: "red",
          text: "In afwachting"
        },
        {
          status: 1,
          color: "light-green",
          text: "Bevestigd"
        }
      ]
    }
  },
  methods: {
    toDateString(n) {
      return toDateString(n)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    reset() {
      let file = document.getElementById("camt053")
      file.value = ""
      this.invoices = []
      this.transactions = []
    },
    readFile() {
      var fileInput = document.getElementById("camt053")
      var reader = new FileReader()
      let thiss = this
      reader.onload = function() {
        let result = thiss.processData(reader.result)
        let invoices = result.reduce(
          (acc, cur) => acc.concat(cur.invoiceNumbers),
          []
        )
        thiss.transactions = result
        thiss.fetchInvoicesFromMap(invoices)
      }
      reader.readAsBinaryString(fileInput.files[0])
    },
    processData(allText) {
      let transactions = []
      let parser = new DOMParser()
      let xmlDoc = parser.parseFromString(allText, "text/xml")
      let entries = xmlDoc.getElementsByTagName("Ntry")
      let invoiceNumberLength = this.$store.state.settings.invoiceNumber.toString().length
      let re = `\\d{${invoiceNumberLength}}`
      let regex = new RegExp(re, "g")
      for (let i = 0; i < entries.length; i++) {
        let creditDebit = entries[i].getElementsByTagName("CdtDbtInd")[0]
          .innerHTML
        if (creditDebit == "CRDT") {
          let entryReference = () => {
           if(entries[i].getElementsByTagName("NtryRef")&&entries[i].getElementsByTagName("NtryRef")[0]
            .innerHTML)
            return entries[i].getElementsByTagName("NtryRef")[0]
              .innerHTML
           else{
          let NtryDtls = entries[i].getElementsByTagName("NtryDtls")[0]
          let RmtInf = NtryDtls.getElementsByTagName("RmtInf")[0]
          let Ustrd = RmtInf.getElementsByTagName("Ustrd")[0]
          return Ustrd.innerHTML
           }
          }
          let BookgDt = entries[i].getElementsByTagName("BookgDt")[0]
          var bookingDate = BookgDt.getElementsByTagName("Dt")[0].innerHTML
          var amount = entries[i].getElementsByTagName("Amt")[0].innerHTML
          let entryDetails = entries[i].getElementsByTagName("NtryDtls")[0]
          let transactionDetails = entryDetails.getElementsByTagName(
            "TxDtls"
          )[0]
          let relatedParties = transactionDetails.getElementsByTagName(
            "RltdPties"
          )[0]
          let debtor = relatedParties.getElementsByTagName("Dbtr")[0]
          var debtorName = debtor.getElementsByTagName("Nm")[0].innerHTML
          let debtorAccount = relatedParties.getElementsByTagName("DbtrAcct")[0]
          let debtorAccountIdentification = debtorAccount.getElementsByTagName(
            "Id"
          )[0]
          var debtorAccountNumber = debtorAccountIdentification.getElementsByTagName(
            "IBAN"
          )[0].innerHTML
          let RemittanceInformation = transactionDetails.getElementsByTagName(
            "RmtInf"
          )[0]
          var transactionDescription = ""
          if (RemittanceInformation) {
            if (RemittanceInformation.getElementsByTagName("Strd")[0]) {
              let structured = RemittanceInformation.getElementsByTagName(
                "Strd"
              )[0]
              let CreditorReferenceInformation = structured.getElementsByTagName(
                "CdtrRefInf"
              )[0]
              let type = CreditorReferenceInformation.getElementsByTagName(
                "Tp"
              )[0]
              let CodeOrProprietary = type.getElementsByTagName("CdOrPrtry")[0]
              let code = CodeOrProprietary.getElementsByTagName("Cd")[0]
                .innerHTML
              let issuer = CodeOrProprietary.getElementsByTagName("Issr")[0]
              let reference = CreditorReferenceInformation.getElementsByTagName(
                "Ref"
              )[0].innerHTML
              transactionDescription = `${code} ${issuer} ${reference}`
            } else {
              transactionDescription = RemittanceInformation.getElementsByTagName(
                "Ustrd"
              )[0].innerHTML
            }
          }
          let invoiceNumbers = []
          let matchInvoiceNumbers = transactionDescription.match(regex)
          if (matchInvoiceNumbers)
            invoiceNumbers = matchInvoiceNumbers.map(invoice =>
              parseInt(invoice)
            )
          let transaction = {
            status: 0,
            success: false,
            id: entryReference,
            bookingDate: new Date(bookingDate),
            debtorName: debtorName,
            debtorAccountNumber: debtorAccountNumber,
            description: transactionDescription,
            amount: parseFloat(amount),
            invoices: [],
            invoiceNumbers: invoiceNumbers
          }
          transactions.push(transaction)
        }
      }
      return transactions
    },
    async fetchInvoicesFromMap(map) {
      let response = await fetchGET("fetchInvoicesFromMap", { invoices: map })
      this.invoices = response
      for (let transaction of this.transactions) {
        for (let invoiceNumber of transaction.invoiceNumbers) {
          let invoice = response.find(
            invoice => invoice.invoiceNumber == invoiceNumber
          )
          if (invoice) {
            invoice.transactionAmount = 0
            let transactionIdIndex = invoice.payments.findIndex(
              payment => transaction.id == payment.id
            )
            if (transactionIdIndex == -1) {
              if (transaction.invoices.length) {
                let totalDebit = transaction.invoices.reduce(
                  (acc, cur) => acc + cur.debit,
                  0 + invoice.debit
                )
                if (
                  transaction.amount - totalDebit < 0.01 &&
                  transaction.amount - totalDebit > -0.01
                ) {
                  invoice.success = true
                  transaction.success = true
                } else {
                  invoice.success = false
                  transaction.success = false
                }
                transaction.invoices.push(invoice)
              } else {
                if (
                  transaction.amount - invoice.debit < 0.01 &&
                  transaction.amount - invoice.debit > -0.01
                ) {
                  invoice.success = true
                  transaction.success = true
                } else {
                  invoice.success = false
                  transaction.success = false
                }
                transaction.invoices.push(invoice)
              }
            } else transaction.status = 1
          }
        }
      }
    },
    async confirmTransaction(transaction, index) {
      try {
        for (let invoice of transaction.invoices) {
          let date = transaction.bookingDate.toISOString().split("T")[0]
          const [year, month, day] = date.split("-")
          let dateFormatted = `${day}-${month}-${year}`
          invoice.payments.push({
            id: transaction.id,
            amount: invoice.debit,
            paid: true,
            payMethod: "Bank",
            date: date,
            dateFormatted: dateFormatted
          })
          invoice.status = 2
          invoice.debit = 0
          invoice.credit = invoice.grossTotal
          let response = await fetchPOST("confirmTransaction", invoice)
          if (response.nModified == 0) throw new Error("Invoice not updated")
          successHandler("Transactie bevestigd")
        }
        this.transactions[index].status = 1
      } catch (e) {
        errorHandler(e, "Fout bij bevestigen")
      }
    },
    async confirmTransactionUnderReview(transaction, index) {
      for (let invoice of transaction.invoices) {
        let date = transaction.bookingDate.toISOString().split("T")[0]
        const [year, month, day] = date.split("-")
        let dateFormatted = `${day}-${month}-${year}`
        invoice.payments.push({
          id: transaction.id,
          amount: invoice.transactionAmount,
          paid: true,
          payMethod: "Bank",
          date: date,
          dateFormatted: dateFormatted
        })
        let remainder = invoice.debit - invoice.transactionAmount
        invoice.debit = remainder < 0.01 && remainder > -0.01 ? 0 : remainder
        invoice.status = invoice.debit == 0 ? 2 : 1
        invoice.credit = invoice.credit + invoice.transactionAmount
        let response = await fetchPOST("confirmTransaction", invoice)
        if (response.nModified == 0) throw new Error("Invoice not updated")
        successHandler("Transactie bevestigd")
        this.closeReviewTransactionModal()
      }
      this.transactions[index].status = 1
    },
    openReviewTransactionModal(transaction, index) {
      this.transactionUnderReview = transaction
      this.transactionUnderReviewIndex = index
      this.reviewTransactionModal = true
    },
    closeReviewTransactionModal() {
      this.reviewTransactionModal = false
    }
  },
  components: { reviewTransactionModal }
}
</script>
<style scoped>
#file-input {
  width: 300px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #ddd;
}
td {
  height: 50px;
  border: 1px solid lightgrey;
}
.hasError {
  background: red;
  color: white;
}
.noError {
  background: green;
  color: white;
}
.v-application .red,
.v-application .orange,
.v-application .light-green,
.v-application .black {
  color: white;
  border-color: #013fba !important;
}
</style>